import React, { useEffect } from "react";
import history from "@/util/history";
import { Modal } from "antd";

import par1 from "@/assets/img_wc/partner1.png";
import par2 from "@/assets/img_wc/partner2.png";
import par3 from "@/assets/img_wc/partner3.png";
import par4 from "@/assets/img_wc/partner4.png";
import par5 from "@/assets/img_wc/partner5.png";
import par6 from "@/assets/img_wc/partner6.png";
import par7 from "@/assets/img_wc/partner7.png";
import par8 from "@/assets/img_wc/partner8.png";
import par9 from "@/assets/img_wc/partner9.png";
import par10 from "@/assets/img_wc/partner10.png";
import par11 from "@/assets/img_wc/partner11.png";
import par12 from "@/assets/img_wc/partner12.png";
import par13 from "@/assets/img_wc/partner13.png";
import par14 from "@/assets/img_wc/partner14.png";
import incred from "@/assets/img_wc/icon-incred.png";
import style from "./index.module.scss";

const partner1 = [
  { src: par1, url: "" },
  { src: par2, url: "" },
  { src: par3, url: "" },
  { src: par4, url: "" },
  { src: par5, url: "" },
];
const partner2 = [
  { src: par6, url: "" },
  { src: par7, url: "" },
  { src: par8, url: "" },
  { src: par9, url: "https://bhanixfinance.com/ourpartners.html" },
  {
    src: incred,
    url: "",
    showDialog: true,
    title: "Grievance Redressal Mechanism",
    content: (
      <div>
        1. How to log in a complaint/where can a complaint be made
        <br />
        Any customer having a grievance/complaint/feedback with respect to the
        product and services offered by InCred Financial Services Limited
        (hereinafter referred to as 'the Company') may write to the Company's
        Customer Service Department through any of the following channels:{" "}
        <br />
        Call at 18001022192 <br />
        Email:care@incred.com <br />
        You may write a letter at the address:Unit No. 1203, 12th floor, B Wing,
        The Capital, Plot No. C - 70, G Block, Bandra - Kurla Complex
        Mumbai:400051 Maharashtra <br />
        Website: www.incred.com <br />
        2. Whom to approach for redressal <br />
        Customers are requested to first raise their concerns through any of the
        channels mentioned above. And if the same is not resolved within 5 days
        or if the customer is not satisfied with the solution provided by the
        customer care service, then the customer may follow below escalations
        for resolving their grievances, <br />
        Name: Ms. Rosy Dsouza <br />
        Contact: 022-42117799 <br />
        Email Id: care@incred.com <br />
        In case the complaint is not resolved within a period of five days or if
        the customer is not satisfied with the solution provided by Mr. Kiran
        Gawand then the customer may approach the Grievance Redressal Officer.
        The name and contact details of the Grievance Redressal Officer is as
        follows: <br />
        Grievance Redressal Officer Name: Mr. Vaidyanathan Ramamoorthy <br />
        E-mail ID:{" "}
        <a className={style.link} href="mail:incred.grievance@incred.com">
          incred.grievance@incred.com
        </a>{" "}
        <br />
        ETelephone no.: 022-42117799 <br />
        Address: Incred Financial Services Limited, 1203, 12th Floor, B Wing,
        The Capital, Bandra Kurla Complex, Mumbai - 400 051 <br />
        <a
          className={style.link}
          href="https://www.incred.com/grievance.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.incred.com/grievance.html
        </a>{" "}
        <br />
        3. Details of InCred Personal Loan: <br />
        Details of InCred Personal loans can be found at{" "}
        <a
          className={style.link}
          href="https://personal-loans.incred.com/personal-loan"
          target="_blank"
          rel="noreferrer"
        >
          https://personal-loans.incred.com/personal-loan
        </a>{" "}
        <br />
        RBI Sachet Portal:{" "}
        <a
          className={style.link}
          href="https://sachet.rbi.org.in/"
          target="_blank"
          rel="noreferrer"
        >
          https://sachet.rbi.org.in/
        </a>{" "}
      </div>
    ),
  },
];
const partner3 = [
  { src: par10, url: "" },
  { src: par11, url: "" },
  { src: par12, url: "" },
  { src: par13, url: "" },
  { src: par14, url: "" },
];

const Company = () => {
  const parterRef = React.createRef();

  useEffect(() => {
    if (history.location.search.includes("needScroll")) {
      setTimeout(() => {
        if (this.parterRef.current) {
          this.parterRef.current.scrollIntoView({ block: "start" });
        }
      }, 500);
    }
  }, []);

  return (
    <div className={style.company}>
      <div>
        <img
          width="100%"
          src={require("@/assets/img_wc/com-banner.png")}
          alt="Banner"
        />
      </div>
      <div className={style.aboutUs}>
        <img
          width="100%"
          src={require("@/assets/img_wc/about_banner2.png")}
          alt="About Us"
        />
        <div
          className={style.btn}
          onClick={() => {
            history.push("./contactUs");
          }}
        ></div>
      </div>
      <div id="tab3">
        <img
          width="100%"
          src={require("@/assets/img_wc/about_banner3.png")}
          alt="Our Mission"
        />
      </div>
      {/* <div id="tab4">
        <img width="100%" src={require('@/assets/img_wc/about_banner4.png')} alt="" />
      </div> */}
      <div id="tab5">
        <img
          width="100%"
          src={require("@/assets/img_wc/about_banner5.png")}
          alt="Growhiz Help You"
        />
      </div>
      <div id="tab5" ref={parterRef} className={style.partner}>
        <h1 className={style.title}>Our Partners</h1>
        <div className={style.imgBox}>
          <div>
            {partner1.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.src}
                  alt="Partner"
                  onClick={
                    item.url
                      ? () => (window.location.href = item.url)
                      : () => {
                          if (item.showDialog) {
                            Modal.info({
                              icon: "",
                              width: 800,
                              title: item.title,
                              content: item.content,
                            });
                          }
                        }
                  }
                />
              );
            })}
          </div>
          <div>
            {partner2.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.src}
                  alt="Partner"
                  onClick={
                    item.url
                      ? () => (window.location.href = item.url)
                      : () => {
                          if (item.showDialog) {
                            Modal.info({
                              icon: "",
                              width: 800,
                              title: item.title,
                              content: item.content,
                            });
                          }
                        }
                  }
                />
              );
            })}
          </div>
          <div>
            {partner3.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.src}
                  alt="Partner"
                  onClick={
                    item.url
                      ? () => (window.location.href = item.url)
                      : () => {
                          if (item.showDialog) {
                            Modal.info({
                              icon: "",
                              width: 800,
                              title: item.title,
                              content: item.content,
                            });
                          }
                        }
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
