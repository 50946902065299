import React, { useState, useRef, useEffect } from "react";
import history from "@/util/history";
import style from "./index.module.scss";

const HEADCOUNT_LIST = [
  {
    title: "Business Development",
    firstContent: (
      <>
        <p>
          If you live and breathe marketing, we need to talk. We're looking for
          a flexible and versatile marketer who will be responsible for the
          growth of our inbound sales channels.
        </p>
        <p>
          Marketing manager responsibilities include tracking and analyzing the
          performance of advertising campaigns, managing the marketing budget
          and ensuring that all marketing material is in line with our brand
          identity. To be successful in this role, you should have hands-on
          experience with web analytics tools and be able to turn creative ideas
          into effective advertising projects.
        </p>

        <p>
          Ultimately, you will help us build and maintain a strong and
          consistent brand through a wide range of online and offline marketing
          channels.
        </p>
      </>
    ),
    otherContent: [
      {
        title: "Job descriptions:",
        content: (
          <ul>
            <li>
              Data sensitive and user oriented based on regularly data reports
              and user analysis, to focus on UPI's core payment quality and
              transaction behaviour, and promotes the resolution of relevant
              partners for problems. Be owner and closely coordinate with R&D
              team, test team, to implement new product requirements and solve
              product problems.
            </li>

            <li>
              As a user and product manager, be familiar with UPI related
              features and ecosystem, pay attention to the latest market trends
              from NPCI, UPI usage scenarios. Optimize product experience around
              UPI registration, payment capability and payment scenarios, and
              improve the conversion rate and coverage of UPI users in the
              platform.
            </li>

            <li>
              Optimize product experience around the scenarios of local life
              services, such as loan services, online travel booking, coupon
              services, etc.
            </li>
          </ul>
        ),
      },
      {
        title: "Job requirements:",
        content: (
          <ul>
            <li>
              Bachelor degree or above, more than 4-5 years working experience
              in to-user-end product manager, fintech product background is
              preferred;
            </li>

            <li>
              Excellent product design ability, document writing ability and
              project management ability;
            </li>

            <li>
              Be good at analyzing user needs by using user interviews, scene
              analysis, user portraits and other professional means of data
              analysis;
            </li>

            <li>
              Proficient in mainstream prototype and mind map design software,
              such as Axure, Figma, Xmind, visio, ppt, etc.;
            </li>

            <li>
              Professional with have strong teamwork spirit, effective
              communication skills, good learning ability. With strong sense of
              responsibility, and can withstand greater work pressure;
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: "Operation Manager",
    firstContent: (
      <>
        <p>
          If you live and breathe marketing, we need to talk. We're looking for
          a flexible and versatile marketer who will be responsible for the
          growth of our inbound sales channels.
        </p>
        <p>
          Marketing manager responsibilities include tracking and analyzing the
          performance of advertising campaigns, managing the marketing budget
          and ensuring that all marketing material is in line with our brand
          identity. To be successful in this role, you should have hands-on
          experience with web analytics tools and be able to turn creative ideas
          into effective advertising projects.
        </p>
        <p>
          Ultimately, you will help us build and maintain a strong and
          consistent brand through a wide range of online and offline marketing
          channels.
        </p>
      </>
    ),
    otherContent: [
      {
        title: "Job requirements:",
        content: (
          <ul>
            <li>
              Bachelor degree or above, majors in finance, science and
              engineering are preferred, with financial knowledge reserves.
            </li>

            <li>
              More than 4 years of relevant work experience in Internet finance,
              with experience in commercial realization management.
            </li>

            <li>
              Understand the advertising platform system and have strong data
              analysis capabilities.
            </li>

            <li>
              Strong anti-pressure ability, proficient in office software.
            </li>
          </ul>
        ),
      },
      {
        title: "Responsibilities:",
        content: (
          <ul>
            <li>
              Responsible for the operation of financial products in India,
              sensitive to revenue growth.
            </li>

            <li>
              Develop a commercial operation strategy, build an operation system
              and a data indicator system.
            </li>

            <li>
              Combining product characteristics, formulate and promote the
              optimization of product strategy and operation strategy.
            </li>

            <li>
              Cooperate with the product technical team to formulate platform
              update iterations.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: "Marketing Manager",
    firstContent: (
      <>
        <p>
          If you live and breathe marketing, we need to talk. We're looking for
          a flexible and versatile marketer who will be responsible for the
          growth of our inbound sales channels.
        </p>
        <p>
          Marketing manager responsibilities include tracking and analyzing the
          performance of advertising campaigns, managing the marketing budget
          and ensuring that all marketing material is in line with our brand
          identity. To be successful in this role, you should have hands-on
          experience with web analytics tools and be able to turn creative ideas
          into effective advertising projects.
        </p>
        <p>
          Ultimately, you will help us build and maintain a strong and
          consistent brand through a wide range of online and offline marketing
          channels.
        </p>
      </>
    ),
    otherContent: [
      {
        title: "Responsibilities",
        content: (
          <ul>
            <li>
              Develop strategies and tactics to get the word out about our
              company and drive qualified traffic to our front door
            </li>

            <li>
              Deploy successful marketing campaigns and own their implementation
              from ideation to execution
            </li>

            <li>
              Experiment with a variety of organic and paid acquisition channels
              like content creation, content curation, pay per click campaigns,
              event management, publicity, social media, lead generation
              campaigns, copywriting, performance analysis
            </li>

            <li>
              Produce valuable and engaging content for our website and blog
              that attracts and converts our target groups
            </li>

            <li>
              Build strategic relationships and partner with key industry
              players, agencies and vendors
            </li>

            <li>
              Prepare and monitor the marketing budget on a quarterly and annual
              basis and allocate funds wisely
            </li>

            <li>
              Oversee and approve marketing material, from website banners to
              hard copy brochures and case studies
            </li>

            <li>
              Measure and report on the performance of marketing campaigns, gain
              insight and assess against goals
            </li>

            <li>
              Analyze consumer behavior and adjust email and advertising
              campaigns accordingly
            </li>
          </ul>
        ),
      },
      {
        title: "Requirements and skills",
        content: (
          <ul>
            <li>
              Demonstrable experience in marketing together with the potential
              and attitude required to learn
            </li>

            <li>
              Proven experience in identifying target audiences and in
              creatively devising and leading across channels marketing
              campaigns that engage, educate and motivate
            </li>

            <li>
              Solid knowledge of website analytics tools (e.g., Google
              Analytics, NetInsight, Omniture, WebTrends)
            </li>

            <li>
              Experience in setting up and optimizing Google Adwords campaigns
            </li>

            <li>
              Numerically literate, comfortable working with numbers, making
              sense of metrics and processing figures with spreadsheets
            </li>

            <li>
              A sense of aesthetics and a love for great copy and witty
              communication
            </li>

            <li>
              Up-to-date with the latest trends and best practices in online
              marketing and measurement
            </li>

            <li>BSc/MSc degree in Marketing or related field</li>
          </ul>
        ),
      },
    ],
  },
];

const Careers = () => {
  const [expandedItems, setExpandedItems] = useState({});
  const contentRefs = useRef(HEADCOUNT_LIST.map(() => React.createRef()));

  const toggleItem = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    // Force a re-render to get correct heights after initial render
    setExpandedItems({ ...expandedItems });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.container}>
      <div className={style.banner}>
        <img
          width="100%"
          src={require("@/assets/img_wc/careers-banner1.png")}
          alt="Banner"
        />
      </div>
      {HEADCOUNT_LIST.map((item, index) => {
        const isExpanded = expandedItems[index];
        return (
          <div key={index} className={style.headcount}>
            <div className={style.item}>
              <div className={style.title}>{item.title}</div>
              <div className={style.content}>{item.firstContent}</div>
              <div
                className={style.expandableContent}
                ref={contentRefs.current[index]}
                style={{
                  maxHeight: isExpanded
                    ? `${contentRefs.current[index].current?.scrollHeight}px`
                    : "0",
                }}
              >
                {item.otherContent.map((subItem, subIndex) => (
                  <div key={index + subIndex} className={style.subItem}>
                    <div className={style.title}>{subItem.title}</div>
                    <div className={style.content}>{subItem.content}</div>
                  </div>
                ))}
              </div>
              <div
                className={`${style.button} ${
                  isExpanded ? style.close : style.open
                }`}
                onClick={() => toggleItem(index)}
              />
            </div>
          </div>
        );
      })}
      <div className={style.banner}>
        <img
          width="100%"
          src={require("@/assets/img_wc/careers-banner2.png")}
          alt="Work With Us"
        />
      </div>
      <div className={style.contactUs}>
        <img
          width="100%"
          src={require("@/assets/img_wc/careers-banner3.png")}
          alt="Contact Us"
        />
        <div
          className={style.button}
          onClick={() => {
            history.push("/contactUs");
          }}
        />
      </div>
    </div>
  );
};

export default Careers;
