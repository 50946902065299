import React from "react";
import style from "./index.module.scss";
const url =
  "https://www.google.com/maps/embed/v1/view?key=AIzaSyB5_7ee6bAik74RO3B2BjZaGexgqQjfOf0&zoom=17&center=19.203856%2C72.968298";

const ContactUs = () => {
  return (
    <div className={style.container}>
      <div id="tab1" className={style.banner}>
        <img
          width="100%"
          src={require("@/assets/img_wc/contactus-bg.png")}
          alt="Contact Us"
        />
      </div>
      <div className={style.addressContainer}>
        <div className={style.address}>
          <div className={style.title}>Contact Us</div>
          <div className={`${style.icon} ${style.address}`} />
          <div className={style.subTitle}>ADDRESS:</div>
          <div className={style.content}>
            {"Office no 1304, Dev Corpora, F.P 463, Cadbury junction, Eastern Express Highway, Khopat, Thane - 400601".toUpperCase()}
          </div>
          <div className={`${style.icon} ${style.email}`} />
          <div className={style.subTitle}>EMAIL:</div>
          <a className={style.content} href="mailto: support@growhiz.com">
            {"support@growhiz.com".toUpperCase()}
          </a>
          <br />
          <a className={style.content} href="mailto: support@finshellpay.com">
            {"support@finshellpay.com".toUpperCase()}
          </a>
        </div>
        <div className={style.map}>
          <iframe
            key="sasa"
            src={url}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="googlemap"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
