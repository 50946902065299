import React from "react";
import history from '../util/history'
import { Router, Route, Switch } from 'react-router-dom'
import Home from '../view/home'
import Company from '../view/company'
import Careers from '../view/careers'
import ContactUs from '../view/contactUs'
import NavBar from '../compontent/navBar/index'
import Footer from '../compontent/footer/index'

const routes = [
  {
    path: "/home",
    component: Home
  },
  {
    path: "/",
    component: Home
  },
  {
    path: "/company",
    component: Company
  },
  {
    path: "/careers",
    component: Careers
  },
  {
    path: "/contactUs",
    component: ContactUs
  },
];

function routers() {
  return (
    <Router history={history}>
      <Route
        render={({ location }) => {
          return (
            <div>
              <React.Suspense fallback>
                <NavBar location={location} />
                <Switch>
                  {routes.map((route, index) => {
                    window.scrollTo(0, 0)
                    return <Route key={index} exact location={location} {...route}/>
                  })}
                  {/* <Redirect path="/" to="/index" /> */}
                </Switch>
                <Footer />
              </React.Suspense>
            </div>
          )
        }}
      ></Route>
    </Router>
  )
}

export default routers;