import React, { useState, useEffect } from "react";
import history from "@/util/history";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../../assets/img_wc/logo.png";
import logoRed from "../../assets/img_wc/logo_red.png";
import downloadImage from "../../assets/img_wc/download.png";
import style from "./index.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const TAB_LIST = [
  {
    title: "HOME",
    path: "/home",
  },
  {
    title: "COMPANY",
    path: "/company",
  },
  {
    title: "CAREERS",
    path: "/careers",
  },
  {
    title: "CONTACT US",
    path: "/contactUs",
  },
];

const NavBar = ({ location }) => {
  const [currentIndex, setCurrentIndex] = useState(location.pathname);
  const [isScroll, setIsScroll] = useState(false);
  const classes = useStyles();

  const jumpUrl = (values) => {
    setCurrentIndex(values.path);
    history.push(values.path);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentIndex(location.pathname);
  }, [location]);

  const handleResize = () => {
    let h5Scroll = {
      scrollTop: 0,
    };
    if (document?.documentElement && document?.documentElement?.scrollTop) {
      h5Scroll.scrollTop = document.documentElement.scrollTop;
    } else if (document?.body) {
      h5Scroll.scrollTop = document.body.scrollTop;
    }
    setIsScroll(h5Scroll.scrollTop > 0);
  };

  return (
    <div className={classes.root}>
      <div className={style.container}>
        <div className={`${style.navBar} ${isScroll && style.bgColor}`}>
          <div className={style.logo}>
            <img
              src={isScroll || currentIndex === "/careers" ? logoRed : logo}
              alt="Logo"
              onClick={() => {
                jumpUrl(TAB_LIST[0]);
              }}
            />
          </div>
          <div className={style.tabs}>
            {TAB_LIST.map((item, index) => (
              <div
                key={index}
                className={`${style.text}
                  ${currentIndex === "/careers" && style.specialColor}
                  ${isScroll && style.textColor}
                `}
                onClick={() => {
                  jumpUrl(item);
                }}
              >
                {item.title}
                <div
                  className={`${style.itemFoot} 
                  ${isScroll && style.footColor}
                  ${item.path === currentIndex && style.selected}`}
                ></div>
              </div>
            ))}
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.finshell.fin">
            <img
              className={style.downLoad}
              src={downloadImage}
              alt="DownLoad Button"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
