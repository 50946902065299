import React from "react";
import style from "./index.module.scss";

export default class footer extends React.Component {
  render() {
    return (
      <div className={style.footer}>
        <img
          className={style.logo}
          src={require("@/assets/img_wc/logo.png")}
          alt="Logo"
        />
        <p className={style.title}>
          copyright © 2020-23 Growhiz Technology Private Limited.
        </p>
      </div>
    );
  }
}
